<template>
  <div class="commonform">
    <el-form
      ref="commonForm"
      :label-width="labelWidth"
      :label-position="labelPosition"
      :model="formData"
    >
      <div v-for="(item, index) in formDataGroups" :key="index">
        <el-col :span="24" v-if="item.title" class="commonform_title mb30">
          <div class="card_title">{{ item.title }}</div>
        </el-col>
        <template v-for="(formitem, fidx) in item.formDataItems">
          <el-col
            v-if="formitem"
            :xs="24"
            :sm="24"
            :md="24"
            :offset="1"
            :lg="formitem.span"
            :xl="formitem.span"
            :key="fidx"
          >
            <el-form-item
              :label-width="formitem.object['label-width'] || null"
              :prop="formitem.prop"
              :label="formitem.label"
              :required="formitem.object.required || false"
              :rules="formitem.object.rules"
              :class="formitem.object.classname || ''"
            >
              <template v-if="formitem.formtype == 'input'">
                <el-input
                  :type="formitem.type || 'text'"
                  :placeholder="formitem.placeholder"
                  v-model="formData[formitem.prop]"
                  :style="formitem.object.style || ''"
                  :disabled="formitem.object.disabled || false"
                />
                <div v-if="formitem.object.tips" class="commonform_tips">
                  {{ formitem.object.tips }}
                </div>
              </template>
              <template v-else-if="formitem.formtype == 'select'">
                <el-select
                  :placeholder="formitem.placeholder"
                  v-model="formData[formitem.prop]"
                  :style="formitem.object.style || ''"
                  :disabled="formitem.object.disabled || false"
                  :clearable="formitem.object.clearable || false"
                  :multiple="formitem.object.multiple || false"
                  :loading="formitem.object.loading || false"
                  @change="(val) => selectChangeHandler(val, formitem, index, fidx)"
                >
                  <el-option
                    v-for="option in formitem.options" :key="option[formitem.object.key || 'value']"
                    :label="option[formitem.object.name || 'name']"
                    :value="option[formitem.object.key||'value']"
                    :disabled="computedOptionsDisabled(option)"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else-if="formitem.formtype == 'switch'">
                <el-switch
                  v-model="formData[formitem.prop]"
                  :value="formData[formitem.prop]"
                  :disabled="formitem.object.disabled || false"
                />
              </template>
              <template v-else-if="formitem.formtype == 'daterange'">
                <el-date-picker
                  v-model="formData[formitem.prop]"
                  type="daterange"
                  range-separator="To"
                  :start-placeholder="formitem.object.startPlaceholder || 'Start Date'"
                  :end-placeholder="formitem.object.endPlaceholder || 'End Date'"
                  :style="formitem.object.style || ''"
                >
                </el-date-picker>
              </template>
              <template v-else-if="formitem.formtype == 'date'">
                <el-date-picker
                  v-model="formData[formitem.prop]"
                  type="date"
                  :prefix-icon="formitem.object.prefixIcon || 'el-icon-date'"
                  :placeholder="formitem.placeholder || 'Please select the Date'"
                  :disabled="formitem.object.disabled || false"
                  :style="formitem.object.style || ''"
                  :value-format="formitem.object.formatDate || 'yyyy-MM-dd'"
                  :picker-options="formitem.object.pickerOptions || {}"
                >
                </el-date-picker>
              </template>
              <template v-else-if="formitem.formtype == 'inputnumber'">
                <el-input-number
                  v-model="formData[formitem.prop]"
                  controls-position="right"
                  :min="formitem.object.min || 1"
                  :max="formitem.object.max || 1000"
                  :disabled="formitem.object.disabled || false"
                  :style="formitem.object.style || ''"
                >
                </el-input-number>
                <div v-if="formitem.object.tips && formitem.object.tips.length > 0" class="commonform_tips">
                  <p v-for="t in formitem.object.tips" :key="t">{{ t }}</p>
                </div>
              </template>
              <template v-else-if="formitem.formtype == 'datetime'">
                <el-date-picker
                  v-model="formData[formitem.prop]"
                  type="datetime"
                  :prefix-icon="formitem.object.prefixIcon || 'el-icon-time'"
                  :placeholder="formitem.placeholder || 'Please select the ...'"
                  :disabled="formitem.object.disabled || false"
                  :style="formitem.object.style || ''"
                  :value-format="formitem.object.formatDate || 'yyyy-MM-dd HH:mm:ss'"
                >
                </el-date-picker>
              </template>
            </el-form-item>
          </el-col>
        </template>
      </div>
      <el-col :span="24" class="d-flex flex-center mt50 mb20">
        <el-button type="info" @click="backHandler">{{ cancelText }}</el-button>
        <el-button type="primary" class="" @click="submitHandler">{{confirmTxt}}</el-button>
      </el-col>
    </el-form>
  </div>
</template>
<script>
import Utils from '@/utils/Utils'
export default {
  props: {
    labelPosition: {
      type: String,
      default() {
        return 'right'
      }
    },
    labelWidth: {
      type: String,
      default() {
        return '240px'
      }
    },
    formDataGroups: {
      type: Array,
      default() {
        return []
      }
    },
    modifyData: {
      type: Object,
      default() {
        return null
      }
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmTxt: {
      type: String,
      default: 'Confirm'
    },
    apifunc: {
      type: Function
    },
    createDataType: {
      type: String,
      default: 'user'
    },
    cancelFunc: {
      type: Function
    }
  },
  data () {
    return {
      formData: {}
    }
  },
  created () {
    this.initData()
  },
  watch: {
    modifyData: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n && !Utils.isEmptyObject(n)) {
          this.$nextTick(() => {
            const keys = Object.keys(this.formData)
            let obj = {
              id: n.id
            }
            if (keys.length > 0) {
              keys.forEach((d) => {
                obj[d] = n[d]
              })
            }
            //const obj = JSON.parse(JSON.stringify(n))
            //Object.assign(this.formData, JSON.parse(JSON.stringify(obj)))
            this.$set(this, 'formData', JSON.parse(JSON.stringify(obj)))
          })
        }
      }
    }
  },
  methods: {
    initData () {
      let obj = {}
      this.formDataGroups.forEach((d) => {
        if (d.formDataItems.length > 0) {
          d.formDataItems.forEach((f) => {
            if (f) {
              if (Object.hasOwn(f, 'value')) {
                obj[f.prop] = f.value
              } else {
                obj[f.prop] = ''
              }
            }
          })
        }
      })
      this.$set(this, 'formData', obj)
    },
    submitHandler () {
      this.isSubmitting = false
      this.$refs['commonForm'].validate(async (valid) => {
        if (valid) {
          if (this.apifunc && !this.isSubmitting) {
            this.isSubmitting = true
            try {
              const obj = {}
              obj[this.createDataType] = {
                ...this.formData
              }
              const res = await this.apifunc(obj)
              if (res.data[this.createDataType]) {
                if (this.createDataType == 'user' && this.$store.state.user.user.id == res.data[this.createDataType].id) {
                  this.$store.dispatch('user/initUserInfo')
                }
                this.$message.success('Submit Success')
                this.$router.back(-1)
              }
            } catch (error) {}
            this.isSubmitting = false
          } else {
            this.$emit('submit', this.formData)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      });
    },
    backHandler () {
      if (!this.cancelFunc) {
        this.$router.back(-1)
      } else {
        this.cancelFunc()
      }
    },
    selectChangeHandler (val, item, index, fidx) {
      if (item.object.nextchange) this.$emit('selectchange', val, item, index, fidx)
    },
    computedOptionsDisabled (option) {
      return option.disabled || (Object.hasOwn(option, 'active') && !option.active) || false
    }
  }
}
</script>

<style lang="scss" scoped>
.commonform {
  padding: 0 10px;
  display: flex;
  height: auto;
  box-sizing: border-box;
  &_tips {
    color: $etxt-color-lightgray;
    font-size: 14px;
    line-height: 22px;
  }
}
.commonform_title {
  padding-left: 10px;
}

::v-deep .width250 .el-form-item__content {
  width: 250px;
}

::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 250px;
}
</style>