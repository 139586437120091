<template>
  <div class="update-patient pb20">
    <el-card :body-style="{ padding: '0px' }">
      <template v-slot:header>
        <StepLine :step-line-list="stepLineList" :currIndex="currStepIdx" />
        <el-form v-if="currStepIdx == 0" ref="topForm" :model="topForm" label-width="210px" class="patient-form">
          <el-form-item label="Name of Referring Institution" prop="from_institution_id" required>
            <el-select v-model="topForm.from_institution_id" placeholder="Please select..." :disabled="isPHI">
              <el-option
                v-for="item in referringInstitutionList"
                :key="item.id"
                :label="item.name"
                :disabled="isPHI"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Patient Search:" prop="from_institution_id">
            <el-autocomplete
              class="inline-input"
              v-model="topForm.patient_id"
              :disabled="!isPHI && !topForm.from_institution_id"
              :fetch-suggestions="querySearchHandler"
              placeholder="Search by IC for existing patients..."
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
        </el-form>
        <div v-if="currStepIdx == 2" class="patient-review-header">
          <div class="patient-review-header_txt d-flex">
            Please confirm that you will be referring the following patient from
            <span style="padding: 0 5px;">{{ selectedReferringInstitution }}</span>
            to
            <span style="padding-left: 5px;">{{selectedDestinationInstitution}}</span>:
          </div>
          <div class="textdarkblue-bold16 mt15">
            <div>{{ formData.name || '-' }}</div>
            <div>{{ formData.ic || '-' }}</div>
          </div>
          <div class="mt15 patient-review-header_txt">Upon submission of this form, a patient record will be listed and his/her profile will be accessible by the destination institution. </div>
        </div>
      </template>
      <div v-if="currStepIdx == 0" class="patient-form-main">
        <CommonForm
          key="profile"
          label-position="right"
          label-width="170px"
          :formDataGroups="formDataGroups"
          :modifyData="modifyData"
          confirmTxt="Next"
          @submit="nextHandler"
        />
      </div>
      <div v-if="currStepIdx == 1" class="update-referral patient-form-main">
        <el-col :span="24" :offset="1">
          <div class="update-patient_txt d-flex">
            <div class="update-patient_label">Name of Referring Institution:    </div>
            <div>{{ selectedReferringInstitution }}</div>
          </div>
        </el-col>
        <CommonForm
          key="referral"
          label-position="right"
          label-width="170px"
          :formDataGroups="referralFormDataGroup"
          :modifyData="referralModifyData"
          confirmTxt="Next"
          cancelText="Previous"
          :cancelFunc="() => currStepIdx -= 1"
          @submit="nextHandler"
        />
      </div>
      <template v-if="currStepIdx == 2" >
        <div class="update-patient-info">
          <div class="textdarkblue-bold16 mt25">
            Patient Profile
          </div>
          <div class="update-patient-info_groups mt10">
            <div
              v-for="item in patientInfoItems"
              :key="item.prop"
              class="update-patient-info_group mt15 d-flex spacebetween"
            >
              <div class="update-patient-info_label">{{ item.label }}</div>
              <div class="update-patient-ino_value textdarkblue">{{ computedVal(item) }}</div>
            </div>
          </div>
        </div>
        <div class="update-patient-info">
          <div class="textdarkblue-bold16 mt25">
            Patient Profile
          </div>
          <div class="update-patient-info_groups mt10">
            <div
              v-for="item in referralInfoItems"
              :key="item.prop"
              class="update-patient-info_group mt15 d-flex spacebetween"
            >
              <div class="update-patient-info_label">{{ item.label }}</div>
              <div v-if="item.prop == 'from_institution_name'" class="update-patient-ino_value textdarkblue">
                {{ selectedReferringInstitution }}
              </div>
              <div v-else-if="item.prop == 'to_institution_name'" class="update-patient-ino_value textdarkblue">
                {{ selectedDestinationInstitution }}
              </div>
              <div v-else-if="item.prop == 'planned_destination_name'" class="update-patient-ino_value textdarkblue">
                {{ selectPlannedDestinationName }}
              </div>
              <!-- {{ formData[item.prop] || '-' }} -->
              <div v-else class="update-patient-ino_value textdarkblue">{{ computedVal(item) }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex mt50 update-patient_btns mb20">
          <el-button type="info" @click="currStepIdx -= 1">Previous</el-button>
          <el-button type="primary" class="" @click="submitHandler">Submit</el-button>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
import StepLine from '@/components/StepLine'
import CommonForm from '@/components/CommonForm'

import {
  getInstitutionList,
  getPatientList,
  createPatient,
  getPatientInfo,
  getDestinationList
} from '@/services'
import { mapState } from 'vuex'
import jsonData from './jsonData'
export default {
  components: {
    StepLine,
    CommonForm
  },
  data () {
    return {
      ...jsonData,
      currStepIdx: 0,
      referringInstitutionList: [],
      topForm: {
        from_institution_id: '',
        patient_id: ''
      },
      selectedReferringInstitution: '',
      selectedDestinationInstitution: '',
      selectPlannedDestinationName: '',
      modifyData: {},
      referralModifyData: {},
      formData: {},
      isPHI: false,
      destinationList: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  mounted () {
    this.initFormData()
  },
  methods: {
    computedVal (item) {
      const str = this.formData[item.prop]
      if (item.prop == 'gender') {
        return str ? (str.charAt(0).toUpperCase()
  + str.slice(1)) : '-'
      }
      if (typeof str === 'boolean') return str ? 'Yes' : 'No'
      return str || '-'
    },
    async initFormData() {
      let kind_eq = 'PHI'
      let destination_kin_eq = 'TCF'
      if (this.user && this.user.institution) {
        this.isPHI = this.user.institution.kind == 'PHI'
        this.topForm.from_institution_id = this.isPHI ? this.user.institution.id : ''
        //kind_eq = this.isPHI ? 'PHI' : this.user.institution.kind
        destination_kin_eq = this.isPHI ? 'TCF' : this.user.institution.kind
        if (this.isPHI) {
          this.selectedReferringInstitution = this.user.institution.name
          
        } else {
          this.selectedDestinationInstitution = this.user.institution.name
          this.referralFormDataGroup[0].formDataItems[0].value = Number(this.user.institution.id)
          this.referralFormDataGroup[0].formDataItems[0].object.disabled = true
          //this.initInstitution(kind_eq, true, true, true)
        }
      }
      this.initInstitution(destination_kin_eq, false, false, true)
      this.initInstitution(kind_eq, true, true)
      this.initDestinationList()
    },
    async initInstitution (kind_eq, isLoading = true, isReferring, isDestination) {
      try {
        const res = await getInstitutionList({kind_eq}, isLoading)
        if (res.data.institutions) {
          if(isDestination) this.referralFormDataGroup[0].formDataItems[0].options = res.data.institutions
          if(isReferring) this.referringInstitutionList = res.data.institutions
        }
      } catch (error) {
        console.log(error)
      }
    },
    async initDestinationList () {
      try {
        const res = await getDestinationList({}, false)
        if (res.data.destinations) {
          this.referralFormDataGroup[0].formDataItems[1].options = res.data.destinations
          this.destinationList = res.data.destinations
        }
      } catch (error) { }
    },
    handleSelect(item) {
      if (item.id) {
        this.initPatientInfo(item.id)
      }
    },
    async initPatientInfo(id) {
      try {
        const res = await getPatientInfo(id)
        if (res.data.patient) {
          const {patient } = res.data
          if (patient.to_institution) {
            patient.to_institution_id = patient.to_institution
          }
          if (patient.planned_destination) {
            patient.planned_destination_id = patient.planned_destination
          }
          this.modifyData = res.data.patient
          //this.referralModifyData = res.data.patient
        }
      } catch (error) {
        
      }
    },
    async querySearchHandler(queryString, cb) {
      const arr = []
      if(queryString) {
        try {
          const res = await getPatientList({
            ic_cont: queryString, 
            from_institution_id_eq: this.topForm.from_institution_id
          }, false)
          if (res.data.patients) {
            res.data.patients.forEach((d) => {
              arr.push({
                value: d.ic + `(${d.name})`,
                ...d
              })
            })
          }
        } catch (error) {
          
        }
      }
      cb(arr)
    },
    nextHandler (form) {
      if (!this.isPHI) {
        if (!this.topForm.from_institution_id) {
          this.$message.error('Please select the Name of Referring Institution')
          return
        }
        if (this.currStepIdx == 0) {
          const list = this.referringInstitutionList.filter((d) => d.id == this.topForm.from_institution_id)
          if (list.length > 0) {
            this.selectedReferringInstitution = list[0].name
          }
        }
      } else {
        if (this.currStepIdx == 1) {
          this.referralFormDataGroup[0].formDataItems[0].object.disabled = true
          const list = this.referralFormDataGroup[0].formDataItems[0].options
          const arr = list.filter((d) => d.id == this.user.institution.id)
          if (arr.length > 0) {
            this.selectedReferringInstitution = arr[0].name
          }
        }
      }
      if (this.currStepIdx == 0) {
        this.formData.from_institution_id = this.topForm.from_institution_id
        this.modifyData = form
      } else if (this.currStepIdx == 1) {
        this.referralModifyData = form
        const idx = this.destinationList.findIndex((d) => d.id == form.planned_destination_id)
        this.selectPlannedDestinationName = idx >= 0 ? this.destinationList[idx].name : ''
      }
      this.currStepIdx += 1
      Object.assign(this.formData, form)
    },
    async submitHandler () {
      try {
        const res = await createPatient(this.formData)
        if (res.data.patient) {
          this.$message.success('Submit Success!')
          this.$router.back(-1)
        }
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-form {
  width: 480px;
  margin: 74px auto 40px;
}

.update-patient_txt {
  font-size: 14px;
  color: $text-color-secondarygray;
  font-weight: 500;
  margin-bottom: 25px;
  margin-left: -10px;
}
::v-deep {
  .el-autocomplete {
    width: 100%;
  }
}
.patient-form-main {
  margin-top: 35px;
  padding: 0 20px 20px;
}

.update-patient_label {
  width: 200px;
  margin-right: 10px;
}
.update-patient-ino_value {
  width: calc(100% - 210px);
}
.patient-review-header {
  width: 100%;
  padding: 0 40px;
  margin: 60px auto 0;
  &_txt {
    font-size: 16px;
    color: $text-color-primary;
    span {
      color: #1D2129;
      font-weight: bold;
    }
  }
}
.update-patient-info {
  width: 100%;
  padding: 0 60px 25px;
  box-sizing: border-box;
  &:nth-of-type(1) {
    border-bottom: $border-style;
  }
  &_label {
    width: 245px;
    font-size: 14px;
    color: $text-color-secondarygray;
    line-height: 22px;
  }
  &_value {
    width: calc(100% - 245px - 35px);
  }
}

.update-patient_btns {
  width: 100%;
  justify-content: center;
}
</style>