/**
 * 公共常量
 */
export const FLIC_ROLE_LIST = [
  {
    name: 'Doctor',
    value: 'doctor'
  },
  {
    name: 'Nurse',
    value: 'nurse'
  },
  {
    name: 'Admin',
    value: 'admin'
  },
  {
    name: 'Allied Health',
    value: 'allied_health'
  },
  {
    name: 'Case Coordinator',
    value: 'case_coordinator'
  },
  {
    name: 'Regulator',
    value: 'regulator'
  },
  {
    name: 'System Administrator',
    value: 'system_admin'
  }
]

export const FLIC_INSTITUTION_KIND_LIST = [
  {
    name: 'PHI',
    value: 'PHI'
  },
  {
    name: 'TCF',
    value: 'TCF'
  },
  {
    name: 'CTF',
    value: 'CTF'
  },
  {
    name: 'CIF',
    value: 'CIF'
  },
  {
    name: 'AIC',
    value: 'AIC'
  },
  {
    name: 'MOH',
    value: 'MOH'
  }
]

export const FLIC_PERMT_ACTIONS = [
  {
    id: 'index',
    name: 'list'
  },
  {
    id: 'show',
    name: 'View'
  },
  {
    id: 'create',
    name: 'Add'
  },
  {
    id: 'update',
    name: 'Edit'
  },
  {
    id: 'destroy',
    name: 'Destroy'
  }
]

export const FLIC_GENDER_LIST = [
  {
    name: 'Female',
    id: 'female'
  },
  {
    name: 'Male',
    id: 'male'
  }
]

export const FLIC_RESIDENCY_LIST = [
  {
    name: 'Foreigner',
    value: 'Foreigner'
  },
  {
    name: 'SG',
    value: 'SG'
  },
  {
    name: 'PR',
    value: 'PR'
  }
]

export const FLIC_RAF_LIST = [
  {
    name: 'I',
    value: 'I'
  },
  {
    name: 'II',
    value: 'II'
  },
  {
    name: 'III',
    value: 'III'
  },
  {
    name: 'IV',
    value: 'IV'
  }
]

export const FLIC_PATIENT_STATUS_LIST = [
  {
    name: 'All',
    value: ''
  },
  {
    name: 'Listed',
    value: 'listed'
  },
  {
    name: 'Admitted',
    value: 'admitted'
  },
  {
    name: 'Discharged',
    value: 'discharged'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'U-turned',
    value: 'u_turned'
  },
  {
    name: 'Bed Assigned',
    value: 'bed_assigned'
  },
  {
    name: 'On Hold',
    value: 'on_hold'
  },
  {
    name: 'Withdrawn',
    value: 'withdrawn'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  },
  {
    name: 'Demise',
    value: 'demise'
  }
]
export const FLIC_DISCHARGE_STATUS_LIST = [
  {
    name: 'None',
    value: 'None'
  },
  {
    name: 'Grabbed',
    value: 'Grabbed'
  },
  {
    name: 'Interviewed',
    value: 'Interviewed'
  },
  {
    name: 'Applied',
    value: 'Applied'
  },
  {
    name: 'Accepted',
    value: 'Accepted'
  }
]
export const FLIC_MIN_REVIEW_TIPS = [
  'Note: Min. Review Frequency = 1 Day means this patient has to be reviewed daily.',
  '2 Days means this patient has to be reviewed every 2 days. '
]
export const FLIC_STATUS_CHANGE_FORM_ITEMS = [
  {
    list: ['accepted', 'rejected', 'on_hold', 'withdrawn'],
    formItems: []
  },
  {
    list: ['demise'],
    formItems: [
      {
        span: 15,
        formtype: 'date',
        prop: 'actual_discharge_date',
        label: 'Actual Discharge Date',
        placeholder: 'Please select the Actual Discharge Date',
        object: {
          style: 'width: 250px;',
          rules: [
            { required: true, message: 'Please select the Actual Discharge Date', trigger: 'blur' }
          ]
        }
      }
    ]
  },
  {
    list: ['u_turned', 'discharged'],
    formItems: [
      {
        span: 15,
        formtype: 'date',
        prop: 'actual_discharge_date',
        label: 'Actual Discharge Date',
        placeholder: 'Please select the Actual Discharge Date',
        object: {
          style: 'width: 250px;',
          rules: [
            { required: true, message: 'Please select the Actual Discharge Date', trigger: 'blur' }
          ]
        }
      },
      {
        span: 15,
        formtype: 'select',
        prop: 'actual_destination_id',
        label: 'Discharge Destination',
        placeholder: 'Please select the Discharge Destination',
        options: [],
        object: {
          style: 'width: 250px;',
          key: 'id',
          rules: [
            { required: true, message: 'Please select the Discharge Destination', trigger: 'blur' }
          ]
        }
      }
    ]
  },
  {
    list: ['admitted'],
    formItems: [
      {
        span: 15,
        formtype: 'date',
        prop: 'actual_admission_date',
        label: 'Actual Admission Date',
        options: [],
        placeholder: 'Please select the Actual Admission Date',
        object: {
          style: 'width: 250px;',
          rules: [
            { required: true, message: 'Please select the Actual Admission Date', trigger: 'blur' }
          ]
        }
      },
      // {
      //   span: 15,
      //   formtype: 'select',
      //   prop: 'raf',
      //   label: 'RAF',
      //   placeholder: 'Please select the RAF',
      //   options: FLIC_RAF_LIST,
      //   object: {
      //     style: 'width: 250px;',
      //     rules: [
      //       { required: true, message: 'Please select the RAF', trigger: 'blur' }
      //     ]
      //   }
      // },
      // {
      //   span: 15,
      //   formtype: 'select',
      //   prop: 'doctor_id',
      //   label: 'Clerking Doctor',
      //   placeholder: 'Please select the Clerking Doctor',
      //   options: [],
      //   object: {
      //     style: 'width: 250px;',
      //     key: 'id',
      //     rules: [
      //       { required: true, message: 'Please select the Clerking Doctor', trigger: 'blur' }
      //     ]
      //   }
      // },
      {
        span: 19,
        formtype: 'inputnumber',
        prop: 'min_review',
        label: 'Min Review Frequency',
        placeholder: '',
        object: {
          style: 'width: 250px;',
          rules: [
            { required: true, message: 'Please enter the Min Review Frequency', trigger: 'blur' }
          ],
          tips: FLIC_MIN_REVIEW_TIPS
        }
      }
    ]
  },
  // {
  //   list: ['discharged'],
  //   formItems: [
  //     {
  //       span: 15,
  //       formtype: 'date',
  //       prop: 'actual_discharge_date',
  //       label: 'Actual Discharge Date',
  //       placeholder: 'Please select the Actual Discharge Date',
  //       object: {
  //         style: 'width: 250px;',
  //         rules: [
  //           { required: true, message: 'Please select the Actual Discharge Date', trigger: 'blur' }
  //         ],
  //         pickerOptions: {
  //           disabledDate(time) {
  //             return time.getTime() > Date.now()
  //           }
  //         }
  //       }
  //     },
  //     {
  //       span: 15,
  //       formtype: 'select',
  //       prop: 'actual_destination_id',
  //       label: 'Discharge Destination',
  //       placeholder: 'Please select the Discharge Destination',
  //       options: [],
  //       object: {
  //         style: 'width: 250px;',
  //         key: 'id',
  //         rules: [
  //           { required: true, message: 'Please select the Discharge Destination', trigger: 'blur' }
  //         ]
  //       }
  //     }
  //   ]
  // },
  {
    list: ['bed_assigned'],
    formItems: [
      {
        span: 15,
        formtype: 'select',
        prop: 'bed_id',
        label: 'Bed',
        placeholder: 'Please select the Bed',
        options: [],
        object: {
          style: 'width: 250px;',
          key: 'id',
          rules: [
            { required: true, message: 'Please select the Bed', trigger: 'blur' }
          ]
        }
      },
      {
        span: 15,
        formtype: 'date',
        prop: 'planned_admission_date',
        label: 'Planned Admission Date',
        options: [],
        placeholder: 'Please select the Planned Admission Date',
        object: {
          style: 'width: 250px;',
          rules: [
            { required: true, message: 'Please select the Planned Admission Date', trigger: 'blur' }
          ]
        }
      }
    ]
  }
]