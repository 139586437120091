<template>
  <div class="step-line d-flex flex-center">
    <div class="step-line_list d-flex">
      <div
        v-for="(item, index) in stepLineList"
        :key="index"
        class="step-line_item d-flex"
        :class="{'active': currIndex == index, 'done': index < currIndex}"
      >
        <div class="step-line_item_circle d-flex flex-center">
          <i v-if="index < currIndex" class="el-icon-check"></i>
          <template v-else>
            {{ index + 1 }}
          </template>
        </div>
        <div class="step-line_item_name">{{ item.name }}</div>
        <div
          v-if="stepLineList.length >= 2 && index < stepLineList.length - 1"
          class="step-line_item_tail"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stepLineList:{
      type: Array,
      default: () => []
    },
    currIndex: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {}
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.step-line {
  width: 100%;
  &_item {
    position: relative;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      .step-line_item_circle {
        background: $text-color-darkblue;
        color: #fff;
      }
      .step-line_item_name {
        color: #1d2129;
        font-weight: 500;
      }
    }
    &.done {
      .step-line_item_tail {
        background: $text-color-darkblue;
      }
      .step-line_item_circle {
        background: #EAEEF2;
      }
      .step-line_item_name {
        color: #1d2129;
        font-weight: normal;
      }
    }
    &_tail {
      width: 85px;
      height: 1px;
      background: #e5e5e5;
    }
    &_circle {
      width: 28px;
      height: 28px;
      background: #F2F3F5;
      color: #8a95a3;
      font-size: 14px;
      font-weight: bold;
      border-radius: 28px;
    }
    &_name {
      margin-left: 12px;
      color: #aeaeae;
      font-size: 16px;
      padding-right: 12px;
    }
  }
}
</style>