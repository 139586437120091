import {
  FLIC_GENDER_LIST,
  FLIC_RESIDENCY_LIST,
  FLIC_RAF_LIST
} from '@/utils/constant'
export default {
  stepLineList: [
    {
      name: 'Search / Create Patient Profile'
    },
    {
      name: 'Referral Details'
    },
    {
      name: 'Review and Submit'
    }
  ],
  formDataGroups: [
    {
      title: '',
      name: 0,
      formDataItems: [
        {
          span: 16,
          formtype: 'input',
          prop: 'ic',
          label: 'Patient IC',
          placeholder: 'Please enter the Patient IC',
          object: {
            classname: 'width250',
            rules: [
              { required: true, message: 'Please enter the Patient IC', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'name',
          label: 'Name',
          placeholder: 'Please enter the Name',
          object: {
            classname: 'width250',
            rules: [
              { required: true, message: 'Please enter the Name', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'gender',
          label: 'Gender',
          placeholder: 'Please select the Gender',
          options: FLIC_GENDER_LIST,
          object: {
            classname: 'width250',
            key: 'id',
            rules: [
              { required: true, message: 'Please select the Gender', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'date',
          prop: 'birthday',
          label: 'Date of Birth',
          options: [],
          placeholder: 'Please select the Date of Birth',
          object: {
            classname: 'width250',
            rules: [
              { required: true, message: 'Please select the Date of Birth', trigger: 'blur' }
            ],
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now()
              }
            }
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'residency',
          label: 'Residency',
          options: FLIC_RESIDENCY_LIST,
          placeholder: 'Please select the Residency',
          object: {
            classname: 'width250',
            rules: [
              { required: true, message: 'Please select the Residency', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          type: 'number',
          prop: 'phone',
          label: 'Phone Number',
          placeholder: 'Please enter the Phone Number',
          object: {
            classname: 'width250'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'email',
          label: 'Email',
          placeholder: 'Please enter the Email',
          object: {
            classname: 'width250'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'address',
          label: 'Address',
          placeholder: 'Please enter the Address',
          object: {
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_name',
          label: 'MSP Name',
          placeholder: 'Please enter the MSP Name',
          object: {
            classname: 'width250'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_relation',
          label: 'MSP Relation to Patient',
          placeholder: 'Please enter the MSP Relation to Patient',
          object: {
            classname: 'width250'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_phone',
          label: 'MSP Phone',
          placeholder: 'Please enter the MSP Phone',
          object: {
            classname: 'width250'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_email',
          label: 'MSP Email',
          placeholder: 'Please enter the MSP Email',
          object: {
            classname: 'width250'
          }
        },
        {
          span: 24,
          formtype: 'switch',
          prop: 'mdro',
          label: 'MDRO',
          placeholder: '',
          value: false,
          object: {}
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'mdro_remarks',
          label: 'MDRO Remarks',
          placeholder: 'Please enter the MDRO Remarks',
          object: {
          }
        }
      ]
    }
  ],
  referralFormDataGroup: [
    {
      title: '',
      name: 0,
      formDataItems: [
        {
          span: 18,
          formtype: 'select',
          prop: 'to_institution_id',
          label: 'Destination Institution',
          placeholder: 'Please select the Destination Institution',
          options: [],
          object: {
            key: 'id',
            rules: [
              { required: true, message: 'Please select the Destination Institution', trigger: 'blur' }
            ],
            style: 'width: 300px;'
          }
        },
        {
          span: 18,
          formtype: 'select',
          prop: 'planned_destination_id',
          label: 'Planned Discharge Destination',
          placeholder: 'Please select the Planned Discharge Destination',
          options: [],
          object: {
            key: 'id',
            rules: [
              { required: true, message: 'Please select the Planned Discharge Destination', trigger: 'blur' }
            ],
            style: 'width: 300px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'from_institution_ward_bed',
          label: 'Ward/Bed in Referring Institution',
          placeholder: 'Please enter the ...',
          object: {
            style: 'width: 300px;'
          }
        },
        {
          span: 9,
          formtype: 'inputnumber',
          prop: 'from_institution_los',
          label: 'LOS in Referring Institution',
          placeholder: '',
          object: {
            style: 'width: 300px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'referral_details',
          label: 'Referral Details',
          placeholder: 'Please enter the Referral Details',
          object: {
            style: 'width: 300px;',
            tips: '*Please provide details on MDRO, msw, nok, function, feeding, nursing care, etc.'
          }
        },
        {
          span: 15,
          formtype: 'select',
          prop: 'raf',
          label: 'RAF',
          placeholder: 'Please select ...',
          options: FLIC_RAF_LIST,
          object: {
            style: 'width: 300px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msw_name',
          label: 'MSW Name',
          placeholder: 'Please enter the MSW Name',
          object: {
            style: 'width: 300px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msw_phone',
          label: 'MSW Phone',
          placeholder: 'Please enter the MSW Phone',
          object: {
            style: 'width: 300px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msw_email',
          label: 'MSW Email',
          placeholder: 'Please enter the MSW Email',
          object: {
            style: 'width: 300px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Patient Remarks',
          placeholder: 'Please enter the Patient Remarks',
          object: {
            style: 'width: 300px;'
          }
        }
      ]
    }
  ],
  patientInfoItems: [
    {
      label: 'Name',
      prop: 'name'
    },
    {
      label: 'Patient IC',
      prop: 'ic'
    },
    {
      label: 'Gender',
      prop: 'gender'
    },
    {
      label: 'Date of Birth',
      prop: 'birthday'
    },
    {
      label: 'Residency',
      prop: 'residency'
    },
    {
      label: 'Phone Number',
      prop: 'phone'
    },
    {
      label: 'Email',
      prop: 'email'
    },
    {
      label: 'Address',
      prop: 'address'
    },
    {
      label: 'MSP Name',
      prop: 'msp_name'
    },
    {
      label: 'MSP Relation to Patient',
      prop: 'msp_relation'
    },
    {
      label: 'MSP Phone',
      prop: 'msp_phone'
    },
    {
      label: 'MSP Email',
      prop: 'msp_email'
    },
    {
      label: 'MDRO',
      prop: 'mdro'
    },
    {
      label: 'MDRO Remarks',
      prop: 'mdro_remarks'
    }
  ],
  referralInfoItems: [
    {
      label: 'Referring Institution',
      prop: 'from_institution_name'
    },
    {
      label: 'Destination Institution',
      prop: 'to_institution_name'
    },
    {
      label: 'Planned Discharge Destination',
      prop: 'planned_destination_name'
    },
    {
      label: 'Ward/Bed in Referring Institution',
      prop: 'from_institution_ward_bed'
    },
    {
      label: 'LOS in Referring Institution',
      prop: 'from_institution_los'
    },
    {
      label: 'Referral Details',
      prop: 'referral_details'
    },
    {
      label: 'RAF',
      prop: 'raf'
    },
    {
      label: 'MSW Name',
      prop: 'msw_name'
    },
    {
      label: 'MSW Phone',
      prop: 'msw_phone'
    },
    {
      label: 'MSW Email',
      prop: 'msp_email'
    },
    {
      label: 'Patient Remarks',
      prop: 'remarks'
    }
  ]
}